import React from 'react'
import { Link } from "gatsby";
import styles from '../FirstBlock/mobile.module.scss'
import button from '../buttons.module.scss'



export default class MobileMenu extends React.Component {
  handleCloseMenu = () => {
    document.getElementById('mobile-menu').classList.remove('menu-open');
    document.body.removeAttribute('class');
    document.getElementById('nav-icon2').removeAttribute('class');
    document.getElementById('header-wrap').removeAttribute('class');
  }
  // showModal = () => {
  //     const modal = document.getElementById('modal-sign-up');
  //     if (modal.className === 'show-modal') {
  //       modal.removeAttribute('class');
  //     } else {
  //       modal.className ='show-modal';
  //       document.body.className = 'modal-active';
  //     }
  //       return false;
  //   }
  goToDashboard = () => {
    return window.location.href = 'https://web.duefocus.com/login';
  }
  openSignUp = () => {
    return window.location.href = '/sign-up/';
  }

  render() {
    return (
      <>
        <div id='mobile-menu' className={styles.mobileMenu}>
          <div>
            <Link onClick={this.handleCloseMenu} to='/'>Home</Link>
            {/* <Link onClick={this.handleCloseMenu} to='/integrations/'>Integration</Link> */}
            <Link onClick={this.handleCloseMenu} to='/download/'>Downloads</Link>
            {/* <Link onClick={this.handleCloseMenu}  to='/pricing/'>Pricing</Link> */}
            {/* <Link onClick={this.handleCloseMenu} to='/faq/'>FAQ</Link> */}
            <div>
              <button onClick={this.goToDashboard} className={button.buttonSignIn}>Sign In</button>
              <button onClick={this.openSignUp} className={button.buttonSignUp}>Sign Up</button>
            </div>
          </div>
        </div>
      </>
    )
  }
}
