import React from "react"
import styles from '../Footer/style.module.scss'
import MobileMenu from '../FirstBlock/mobileMenu'
// import ModalSignUp from '../ModalSignUp/index'
import { Link } from 'gatsby'

import logo from "../img/duefocusLogo .svg";
import medium from "../img/blog.svg";
import email from "../img/email.svg";
import twitter from "../img/twitter.svg";
import facebook from "../img/facebook.svg";
import roadMap from "../img/icons/roadmap.png";

import cmmi from "../img/cmmi.png";
import code from "../img/code.png";
import linkedinFooter from "../img/linkedin-footer.svg";
import isoLogo from "../img/iso-logo.png";
import learn1 from "../img/learn1.png";
import rank from "../img/rank.png";
import task from "../img/task.png";
import duefocus from "../img/duefocus.svg";
import fb from "../img/fb.svg";
import tw from "../img/tw.svg";
import m from "../img/m.svg";
import focus1 from "../img/focus1.png";

import goTop from '../img/icons/go_up_light.svg'
import button from "../buttons.module.scss";




export default class Footer extends React.Component {

  state = {
    fastUpHidden: true
  }
  componentDidMount() {
    window.addEventListener("scroll", this.isFastUpVisible);
  }
  isFastUpVisible = () => {
    if (window.pageYOffset > 500) {
      this.setState({
        fastUpHidden: false
      })
    } else {
      this.setState({
        fastUpHidden: true
      })
    }
  }
  goToTop = () => {
    let timer;
    if (window.pageYOffset > 0) {
      window.scrollTo(0, window.pageYOffset - 400);
      timer = setTimeout(this.goToTop, 20)
    } else {
      clearTimeout(timer);
      window.scrollTo(0, 0);
    }
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.isFastUpVisible);
  }
  openSignUp = () => {
    return window.location.href = '/sign-up/';
  }
  goToDashboard = () => {
    return window.location.href = 'https://web.duefocus.com/login';
  }

  render() {
    return (
      <>
        {/* <div className={styles.footer}>
            <div className={styles.footerHolder}>
                <div className={`${styles.footerItem} ${styles.footerItemOrder}`}>
                    <div className={styles.flexButtons}>
                        <span className={styles.footerItemTitle}>Time to Get Started</span>
                        <button onClick={this.openSignUp} className={button.buttonSignUp}>Sign Up</button>
                        <button onClick={this.goToDashboard} className={styles.buttonSignIn}>Log In</button>
                    </div>
                    <div className={styles.flexButtons}>
                        <Link to='/'><img className={styles.logo} alt='duefocus' src={logo}/></Link>
                        <span className={styles.rights} >© {new Date().getFullYear()}. All rights reserved.</span>
                        <span className={styles.links}><Link to='/terms-of-use/'>Terms of Use</Link>, <Link to='/cookies-policy/'>Cookie Policy</Link>, <Link to='/privacy-policy/'>Privacy Policy</Link></span>
                    </div>
                </div>
                <div className={styles.footerItemLinks}>
                <div className={styles.footerItem}>
                    <span className={styles.footerItemTitleLinks}>About</span>
                    <ul>
                        <li><Link to="/integrations/">Integrations</Link></li>
                        <li><Link to="/faq/">FAQ</Link></li>
                        <li><Link to="/download/">Downloads</Link></li>
                        <li><Link to="/pricing/">Pricing</Link></li>
                    </ul>
                </div>
                <div className={styles.footerItem}>
                    <span className={styles.footerItemTitleLinks}>Contact</span>
                    <ul className={styles.socialLinks}>
                        <li><a className={styles.Roadmap} target='_blank' rel="noopener noreferrer" href='https://duefocus.canny.io/'><div><img alt='twitter' src={roadMap}></img>Roadmap</div></a></li>
                        <li><a target='_blank' rel="noopener noreferrer" href='https://twitter.com/DueFocus'><div><img className={styles.socialImg} alt='twitter' src={twitter}></img>Twitter</div></a></li>
                        <li><a target='_blank' rel="noopener noreferrer" href='https://www.facebook.com/DueFocusWeb'><div><img className={styles.socialImg} alt='facebook' src={facebook}></img>Facebook</div></a></li>
                        <li><a target='_blank' rel="noopener noreferrer" href='https://medium.com/diligences-inc'><div><img className={styles.socialImg} alt='medium' src={medium} ></img>Medium</div></a></li>
                        <li><a target='_blank' rel="noopener noreferrer" href='mailto:support@duefocus.com'><div><img className={styles.socialImg} alt='Email Us' src={email}></img>Email Us</div></a></li>
                    </ul>
                </div>
                <div className={styles.footerItem}>
                    <span className={styles.footerItemTitleLinks}>Integrations</span>
                    <ul>
                        <li><Link to="/time-tracking-jira/">Jira</Link></li>
                        <li><Link to="/time-tracking-trello/">Trello</Link></li>
                        <li><Link to="/time-tracking-asana/">Asana</Link></li>
                        <li><Link to="/time-tracking-github/">Github</Link></li>
                        <li><Link to="/time-tracking-clickup/">ClickUp</Link></li>
                        <li><Link to="/time-tracking-gitlab/">Gitlab</Link></li>
                        <li><Link to="/time-tracking-redbooth/">Redbooth</Link></li>
                        <li><Link to="/integrations/">See all</Link></li>
                    </ul>
                </div>
                </div>
            </div>
            <img
                src={goTop}
                hidden={this.state.fastUpHidden}
                className={styles.goTopArrow}
                onClick={this.goToTop}
                alt="Go top"
            />
        </div> */}
        <div id="d-pre-footer" className="d-desktop-visible">
          <div className="container-xl">
            <div className="d-prefoot-products">
              <a href="https://duefocus.com/" target="_blank"><img src={focus1} alt="duefocus" width="127" height="auto" /></a>
              <a href="https://duecode.io/" target="_blank"><img src={code} alt="duecode" width="165" height="auto" /></a>
              <a href="https://duerank.com/" target="_blank"><img src={rank} alt="duerank" width="169" height="auto" /></a>
              <a href="https://duelearn.com/" target="_blank"><img src={learn1} alt="duelearn" width="143" height="auto" /></a>
              <a href="https://marketplace.atlassian.com/apps/1224361/duetask-for-jira/?ref=producthunt" target="_blank"><img src={task} alt="duetask" width="173" height="auto" /></a>
            </div>
          </div>
        </div>
        <footer id="df-footer-n">
          <div className="container-xl">
            <div className="row">
              <div className="col-df-3 df-footer-first">
                <Link to='/'><img src={duefocus} alt="Duefocus logo" width="172" height="auto" /></Link>
                {/* <a href="https://duefocus.com/"><img src={duefocus} alt="Duefocus logo" width="172" height="auto" /></a> */}
                <div className="d-footer-slogan d-color-white">
                  <p>Track work hours with a single button</p>
                </div>
                <div className="d-footer-social d-desktop-visible">
                  <a rel="noopener noreferrer" href="https://www.facebook.com/DueFocusWeb" target="_blank"><img src={fb} alt="facebook" width="30" height="auto" /></a>
                  <a rel="noopener noreferrer" href="https://twitter.com/DueFocus" target="_blank"><img src={tw} alt="twitter" width="30" height="auto" /></a>
                </div>
              </div>
              <div className="col-df-4">
                <p><a className="d-color-white" href="https://diligences.com/" target="_blank">Diligences family</a></p>
                <p>
                  <a className="d-color-white" href="https://duefocus.com/" target="_blank"><strong>Duefocus.</strong> Time tracking</a>
                </p>
                <p>
                  <a className="d-color-white" href="https://duecode.io/" target="_blank"><strong>Duecode.</strong> Engineering metrics</a>
                </p>
                <p>
                  <a className="d-color-white" href="https://duerank.com/" target="_blank"><strong>Duerank.</strong> Global developers leaderboard</a>
                </p>
                <p>
                  <a className="d-color-white" href="https://duelearn.com/" target="_blank"><strong>Duelearn.</strong> Writing better code</a>
                </p>
                <p>
                  <a className="d-color-white" href="https://marketplace.atlassian.com/apps/1224361/duetask-for-jira/?ref=producthunt" target="_blank"><strong>Duetask.</strong> Set realistic estimates</a>
                </p>
              </div>
              <div className="col-df-2">
                <p className="d-color-white">ABOUT</p>
                <p>
                  <Link className="d-color-white" to='/terms-of-use/' target="_blank">Terms of Use</Link>
                  {/* <a className="d-color-white" href="" target="_blank">Integrations</a> */}
                </p>
                <p>
                  <Link className="d-color-white" to='/privacy-policy/' target="_blank">Privacy Policy</Link>
                  {/* <a className="d-color-white" href="" target="_blank">FAQ</a> */}
                </p>
                <p>
                  <Link className="d-color-white" to='/cookies-policy/' target="_blank">Cookie Policy</Link>
                  {/* <a className="d-color-white" href="" target="_blank">Downloads</a> */}
                </p>
                {/* <p>
                  <a className="d-color-white" href="" target="_blank">Pricing</a>
                </p> */}
              </div>
              <div className="col-df-3">
                <p className="d-color-white">CONTACT US</p>
                <p className="d-color-white">297 NV-207 Stateline, NV 89449 USA</p>
                <p className="d-color-white">+1 310-388-9334</p>
                <p>
                  <a className="d-color-white" rel="noopener noreferrer" href='mailto:support@duefocus.com' target="_blank">support@diligences.com</a>
                </p>
                <div className="d-footer-social d-mob-visible">
                  <a rel="noopener noreferrer" href="https://www.facebook.com/DueFocusWeb" target="_blank"><img src={fb} alt="facebook" width="38" height="auto" /></a>
                  <a rel="noopener noreferrer" href="https://twitter.com/DueFocus" target="_blank"><img src={tw} alt="twitter" width="38" height="auto" /></a>
                </div>
                <div className="d-footer-certificates">
                  <a href="https://cmmiinstitute.com/pars/appraisals/32381/historical" target="_blank"><img src={cmmi} alt="CMMI" width="70" height="auto" /></a>
                  <a href="http://sindex20.qarea.org:14003/images/ISO-Diligences.jpg" target="_blank"><img src={isoLogo} alt="ISO" width="80" height="auto" /></a>
                </div>
              </div>
            </div>
            <hr className="d-hr-footer" />
            <div className="d-footer-rights d-color-white">
              © 2022 Duefocus.com., all rights reserved.
            </div>
          </div>
        </footer>
        <MobileMenu />
        {/* <ModalSignUp /> */}
      </>
    )
  }
}
